<template>
  <div id="pc" class="etnersWrap wrapbg">
    <user-main-header-area-en></user-main-header-area-en>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
    <user-main-footer-area-en></user-main-footer-area-en>
  </div>
</template>

<script>
  import UserMainHeaderAreaEn from '../fragments/UserMainHeaderAreaEn'
  import UserMainFooterAreaEn from '../fragments/UserMainFooterAreaEn'

  export default {
    name      : '',
    components: {
      UserMainHeaderAreaEn,
      UserMainFooterAreaEn
    },
  }
</script>
